import { initVirtualTourModal } from "./modal";

export const virtualTourTriggers = (widget) => {
    const triggers = widget.querySelectorAll('.ig-dmn__vtour-trigger');

    if ( triggers ) {
        triggers.forEach(trigger => {
            trigger.addEventListener('click', (event) => {
                event.preventDefault();

                // Get the bookingTypeId from the trigger
                const bookingType = trigger.getAttribute('data-booking-type-id');

                initVirtualTourModal(widget, bookingType);
            });
        });
    }
}