import { apiPostRequest } from '.';
import { getDmnData } from '../helpers/bookingData';

export const getOffers = async function (widget) {
    const bookingForm = widget.querySelector('.ig-dmn__booking-form');
    let formData = new FormData(bookingForm);
    formData.append('action', 'getOffers');

    const venueId = getDmnData('venue')._id;
    if(venueId){
        formData.append('venueID', venueId);
    }

    const responseJson = await apiPostRequest(formData);

    return responseJson;
}