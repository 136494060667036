import { apiPostRequest } from "../api";
import * as bookingData from "../helpers/bookingData";

export const getOffersHTML = async (offers) => {
    const offersData = await getOffersDataForAjax(offers);
    const formData = new FormData();
    formData.append('offers', offersData);
    formData.append('action', 'getOffersHtml');

    const responseJson = await apiPostRequest(formData);

    return responseJson;
};

const getOffersDataForAjax = async (offers) => {
    let offersData = [];
    const activeOffer = bookingData.getDmnData('activeOffer');

    offers.forEach(offer => {
        const offerData = {
            id: offer._id,
            title: offer.title,
            selected: offer._id === activeOffer,
        };

        offersData.push(offerData);
    });

    return JSON.stringify(offersData);
};