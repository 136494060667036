import { apiPostRequest } from ".";
import { getDmnData } from '../helpers/bookingData';

export const getBookingRules = async (widget) => {
    const type = widget.querySelector('.ig-dmn__form-dmn-type').value;
    const date = widget.querySelector('.ig-dmn__form-date').value;
    const formData = new FormData();

    formData.append('type', type);
    formData.append('date', date);
    formData.append('action', 'getBookingRules');

    const venueId = getDmnData('venue')._id;
    if(venueId){
        formData.append('venueID', venueId);
    }

    const bookingRules = await apiPostRequest(formData);

    return bookingRules;
}