import { getBookingRules } from "./bookingRules";
import { getBookingTypeDetails } from "../helpers/bookingData";

export const getBookingDuration = async (widget) => {
    let duration = '';
    let numPeople = widget.querySelector('.ig-dmn__form-guests').value;
    const bookingRules = await getBookingRules(widget);

    if ( bookingRules.no_duration ) {
        return duration;
    }

    let minDuration = bookingRules.min_duration ?? null;
    let maxDuration = bookingRules.max_duration ?? null;

    // Check if numPeople has a value
    if ( numPeople != '' ) {
        // Convert the numPeople variable to an integer
        numPeople = parseInt(numPeople);

        // Get the booking type object for the booking type selected
        const bookingTypeObj = getBookingTypeDetails();
        
        // Check if there are any rules dependant on number of guests set in DMN for the booking type selected
        if ( bookingTypeObj.num_people_overrides ) {
            // Get the min and max duration for the booking type based on the overrides
            const overrideDurations = getDurationOverrides(bookingTypeObj.num_people_overrides, numPeople);

            // If the getDurationOverrides function returns an object instead of false, update the mina dn max duration variables
            if ( overrideDurations ) {
                minDuration = overrideDurations.min;
                maxDuration = overrideDurations.max;
            }
        }
    }

    duration = await calcuateBookingDuration(minDuration, maxDuration);

    return duration;
}

const getDurationOverrides = (overrides, numPeople) => {
    // Iterating through each range in the overrides array
    for (const key in overrides) {
        const range = overrides[key];

        // Checking if numPeople falls within the current range
        if (numPeople >= range.num_people_from && numPeople <= range.num_people_to) {
            // Returning an object with min_duration and max_duration, or null if they don't exist
            return {
                min: range.min_duration || null,
                max: range.max_duration || null
            };
        }
    }

    // If no matching range is found, return false
    return false;
}

const calcuateBookingDuration = async (minDuration, maxDuration) => {
    if ( minDuration === null && maxDuration === null) {
        // No min or max duration set in DMN so return an empty string;
        return '';
    }

    if ( minDuration ) {
        // Return the minDuration if set in DMN
        return minDuration;
    }

    if ( maxDuration ) {
        // If minDuration isn't set, return the maxDuration
        return maxDuration;
    }
}