export const showLoader = (widget) => {
    widget.querySelector('.ig-dmn__loader-container').classList.remove('opacity-0', 'invisible');
    widget.querySelector('.ig-dmn__inner').classList.add('opacity-50');
};

export const hideLoader = (widget) => {
    widget.querySelector('.ig-dmn__loader-container').classList.add('opacity-0', 'invisible');
    widget.querySelector('.ig-dmn__inner').classList.remove('opacity-0', 'invisible', 'opacity-50');
};

export const getWidgetType = (widget) => {
    const widgetType = widget.getAttribute('data-reservation-type');
    let type;

    switch (widgetType) {
        case 'table':
            type = 'General';
            break;
        case 'occasions':
            type = 'Occasions';
            break;
        case 'specific':
            type = 'Specific';
            break;
    }

    return type;
};

export const getWidgetEnv = (widget) => {
    return widget.getAttribute('data-env') ?? '';
};