export const apiPostRequest = async function (data) {
    const response = await fetch(igniteDmnJsVars.ajaxUrl, {
        method: "POST",
        body: data
    });

    if (!response.ok) {
        throw new Error(`[${response.status}] ${response.statusText}`);
    }

    const responseJson = await response.json();

    return responseJson;
}