import { getVenueData } from "../api/venue";

export const getDmnData = (key = null) => {
    const dmnData = localStorage.getItem('dmnData') ? JSON.parse(localStorage.getItem('dmnData')) : {};
    if ( dmnData && key ) {
        return dmnData[key] ?? null;
    } else {
        // return all dmn data
        return dmnData;
    }
};

export const getBookingDetails = () => {
    return getDmnData('bookingDetails');
};

export const getBookingTypeId = () => {
    return getDmnData('bookingType');
};

export const getBookingTypeDetails = (id = null) => {
    // If no id passed through check the localStorage for the active bookingType and use that if set
    const bookingTypeId = id === null ? getBookingTypeId() : id;
    const venueData = getDmnData('venue');

    if ( venueData.booking_types ) {
        const bookingTypeObj = venueData.booking_types.find(bookingType => bookingType.id === bookingTypeId);
        
        return bookingTypeObj;
    } else {
        return null;
    }
};

export const getValidation = () => {
    return getDmnData('validation');
};

export const updateDmnData = (data) => {
    localStorage.setItem('dmnData', JSON.stringify(data));
};

export const updateCurrentStage = (stage) => {
    const dmnData = getDmnData();

    dmnData.stage = stage;

    updateDmnData(dmnData);
};

export const updateVenueDetails = (venueData) => {
    const dmnData = getDmnData();

    dmnData.venue = venueData;

    updateDmnData(dmnData);  
};

export const updateBookingTypes = (bookingTypes) => {
    const dmnData = getDmnData();

    dmnData.bookingTypes = bookingTypes;

    updateDmnData(dmnData);
};

export const updateBookingType = (bookingType) => {
    const dmnData = getDmnData();

    dmnData.bookingType = bookingType;

    updateDmnData(dmnData);
};

export const updateBookingDetails = (bookingDetails) => {
    const dmnData = getDmnData();

    dmnData.bookingDetails = bookingDetails;

    updateDmnData(dmnData);
};

export const updateValidation = (validation) => {
    const dmnData = getDmnData();

    dmnData.validation = validation;

    updateDmnData(dmnData);
};

export const updateOffers = (offers) => {
    const dmnData = getDmnData();

    dmnData.offers = offers;

    updateDmnData(dmnData);
};

export const updateActiveOffer = (offer) => {
    const dmnData = getDmnData();

    dmnData.activeOffer = offer;

    updateDmnData(dmnData);
};

export const resetBookingData = (widget, resetVenue = false) => {
    // Get the venue ID that the booking is being made for
    const venueId = widget.getAttribute('data-venue-id');

    if ( resetVenue && getDmnData('venue') ) {
        updateVenueDetails(null);
    } else {
        // Update the localStorage venue data
        getVenueData(venueId).then(data => {
            updateVenueDetails(data);
        });
    }

    // Remove offers
    if ( getDmnData('offers') ) {
        updateOffers(null);
    }

    // Remove any active offers
    if ( getDmnData('activeOffer') ) {
        updateActiveOffer(null);
    }

    // Remove booking type
    if ( getDmnData('bookingType') ) {
        updateBookingType(null);
    }

    // Remove booking details
    if ( getDmnData('bookingDetails') ) {
        updateBookingDetails(null);
    }

    // Remove validation object
    if ( getDmnData('validation') ) {
        updateValidation(null);
    }
};