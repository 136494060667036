export const logDebugger = (data) => {
    if ( data && (process.env.NODE_ENV === 'development' || window.location.host === 'ignitedmn.wpenginepowered.com') ) {
        console.log(data);
    }
};

export const isEmptyObj =  (myObject) => Object.keys(myObject).length === 0 && myObject.constructor === Object;

export const formatDate = (date) => {
    const year = date.getFullYear();
    const month = formatNumber(date.getMonth() + 1);
    const day = formatNumber(date.getDate());
    
    return `${year}-${month}-${day}`;
};

const formatNumber = (number) => {
    return number < 10 ? '0' + number : number;
};

export const getDayFromDate = (date) => {
    // Create a new Date object with the date specified
    const dateObject = new Date(date);
    // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const dayOfWeek = dateObject.getDay();
    
    return dayOfWeek;
};

export const getDayNameFromDate = (date, lowercase = false) => {
    // Create a new Date object with the date specified
    const dateObject = new Date(date);
    // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const dayOfWeek = dateObject.getDay();
    // Get the day name instead of the index
    const daysOfWeek = getDaysOfWeek(); 
    
    return lowercase ? daysOfWeek[dayOfWeek].toLowerCase() : daysOfWeek[dayOfWeek];
};

export const formatDateForDescription = (inputDateString) => {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const date = new Date(inputDateString);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    // Add the appropriate suffix for the day
    const dayWithSuffix = addSuffixToDay(day);

    return `${dayWithSuffix} ${month} ${year}`;
};
  
// Function to add suffix to day (e.g., 1st, 2nd, 3rd, 4th, etc.)
const addSuffixToDay = (day) => {
    if (day >= 11 && day <= 13) {
        return day + 'th';
    }
    switch (day % 10) {
        case 1: return day + 'st';
        case 2: return day + 'nd';
        case 3: return day + 'rd';
        default: return day + 'th';
    }
};

export const getDaysOfWeek = (lowercase = false) => {
    return lowercase ? ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'] : ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
};

export const generateTimeArray = (openingTime, closingTime, interval) => {
    const result = [];

    // Parse opening and closing times
    const [openHour, openMinute] = openingTime.split(':').map(Number);
    let [closeHour, closeMinute] = closingTime.split(':').map(Number);

    // Adjust closing hour if it is on the next day
    if (closeHour < openHour) {
        closeHour += 24;
    }

    // Generate times in 15-minute intervals
    for (let hour = openHour; hour <= closeHour; hour++) {
        for (let minute = 0; minute < 60; minute += interval) {
            // Check if the generated time is within the opening and closing times
            if ((hour === openHour && minute >= openMinute) || (hour > openHour && hour < closeHour) || (hour === closeHour && minute <= closeMinute)) {
                // Format the time as HH:MM
                const formattedHour = (hour % 24).toString().padStart(2, '0');
                const formattedMinute = minute.toString().padStart(2, '0');
                result.push(`${formattedHour}:${formattedMinute}`);
            }
        }
    }

    return result;
};

export const generateDurations = (minDuration, maxDuration, durationLength) => {
    const result = [];
    
    // Ensure the input parameters are valid
    if (minDuration < 0 || maxDuration < 0 || durationLength <= 0) {
        console.error("Invalid input parameters");
        return result;
    }
  
    // Generate possible durations within the specified range
    for (let duration = minDuration; duration <= maxDuration; duration += durationLength) {
        result.push({
            value: duration,
            label: formatMinutes(duration)
        });
    }
  
    return result;
};

const formatMinutes = (minutes) => {
    if (isNaN(minutes) || minutes < 0) {
        return "Invalid input";
    }

    if (minutes < 60) {
        return minutes + " minute" + (minutes === 1 ? "" : "s");
    } else {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;

        const hoursText = hours + " hour" + (hours === 1 ? "" : "s");
        const minutesText = remainingMinutes > 0 ? " and " + remainingMinutes + " minute" + (remainingMinutes === 1 ? "" : "s") : "";

        return hoursText + minutesText;
    }
}

export const updateElemHTML = async (widget, elem, elemHTML) => {
    widget.querySelector(elem).innerHTML = elemHTML;
};

export const updateTimeDropdown = async (widget, times) => {
    const elem = widget.querySelector('.ig-dmn__form-time');
    let timesHTML = `<option value="">Select a time</option>`;

    times.forEach(time => {
        timesHTML += `<option value="${time}">${time}</option>`;
    });

    // Update the dropdown options
    elem.innerHTML = timesHTML;

    // Remove the disabled attribute
    elem.removeAttribute('disabled');
};

export const updateGuestsDropdown = async (widget, min, max) => {
    const numPeopleDropdown = widget.querySelector('.ig-dmn__form-guests');

    // Generate the dropdown options for the num_people dropdown
    let numPeopleHTML = `<option value="">Select number of people</option>`;
    let i = min;
    while (i <= max) {
        numPeopleHTML += `<option value="${i}">${i}</option>`;
        i++;
    }

    // Update the dropdown options
    numPeopleDropdown.innerHTML = numPeopleHTML;

    // Remove the disabled attribute
    numPeopleDropdown.removeAttribute('disabled');
};

export const updateDurationDropdown = async (widget, durations) => {
    const elem = widget.querySelector('.ig-dmn__form-duration');
    let durationsHTML = `<option value="">Select duration</option>`;

    durations.forEach(duration => {
        durationsHTML += `<option value="${duration.value}">${duration.label}</option>`;
    });

    // Update the dropdown options
    elem.innerHTML = durationsHTML;

    // Remove the disabled attribute
    elem.removeAttribute('disabled');
};

export const isTodayBetweenDates = (startDate, endDate) => {
    // Get today's date
    const today = new Date();
  
    // Convert start and end dates to Date objects if they are not already
    startDate = new Date(startDate);
    endDate = new Date(endDate);
  
    // Check if today is between the start and end dates
    return today >= startDate && today <= endDate;
};

export const getDatesBetween = (startDate, endDate) => {
    const dateArray = [];
    let currentDate = new Date(startDate);
    endDate = new Date(endDate);
  
    while (currentDate <= endDate) {
        dateArray.push(currentDate.toISOString().split('T')[0]);
        currentDate.setDate(currentDate.getDate() + 1);
    }
  
    return dateArray;
};

export const resizeIframe = (iframe) => {
    var iframeDocument = iframe.contentDocument || iframe.contentWindow.document;

    // Get the height of the content inside the iframe
    var contentHeight = Math.max(
        iframeDocument.body.scrollHeight,
        iframeDocument.documentElement.scrollHeight
    );

    // Remove the minHeight styling from the iframe
    iframe.removeAttribute('style');

    // Set the height of the iframe
    iframe.style.height = contentHeight + 'px';
};

// Scroll to the top of the widget
export const scrollToWidgetTop = (widget) => {
    widget.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
};

// Smooth scroll to an element
export const smoothScrollToElement = (widget, selector) =>  {
    if ( widget ) {        
        const targetElement = widget.querySelector(selector);
        const widgetScrollTop = widget.scrollTop;

        if ( targetElement ) {
            // Calculate the target offset from the top of the document
            const targetOffset = targetElement.getBoundingClientRect().top + widgetScrollTop;
        
            // Smooth scroll animation
            widget.scrollTo({
                top: targetOffset,
                behavior: 'smooth'
            });
        } else {
            console.error("Element with selector '" + selector + "' not found.");
        }
    } else {
        console.error('widget is not defined');
    }
}

// Function to check if a parameter exists in the URL
export const hasUrlParameter = (parameterName) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.has(parameterName);
}

// Function to get the value of a parameter from the URL
export const getUrlParameterValue = (parameterName) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(parameterName);
}

export const isBookingFormValid = (form) => {
    const date = form.querySelector('.ig-dmn__form-date').value;
    const time = form.querySelector('.ig-dmn__form-time').value;
    const duration = form.querySelector('.ig-dmn__form-duration').value;
    const numPeople = form.querySelector('.ig-dmn__form-num-people').value;

    const detailsValid = date != '' && time != '' && duration != '' && numPeople != '';
    let dobValid = true;

    // Check if DOB is set to be required
    if ( form.querySelector('.ig-dmn__form-dates') ) {
        const day = form.querySelector('.ig-dmn__form-dob-day').value;
        const month = form.querySelector('.ig-dmn__form-dob-month').value;
        const year = form.querySelector('.ig-dmn__form-dob-year').value;
        const dateString = `${day}${month}${year}`;

        dobValid = day != '' && month != '' && year != '' && isValidDate(dateString);
    }

    return detailsValid && dobValid;
}

const isValidDate = (dateString) => {
    // Check if the input is a string
    if (typeof dateString !== 'string') {
        return false;
    }

    // Ensure the input has exactly 8 characters
    if (dateString.length !== 8) {
        return false;
    }

    // Extract day, month, and year from the input string
    const day = parseInt(dateString.substring(0, 2), 10);
    const month = parseInt(dateString.substring(2, 4), 10);
    const year = parseInt(dateString.substring(4), 10);

    // Check if the extracted values represent a valid date
    const isValidDay = day >= 1 && day <= 31;
    const isValidMonth = month >= 1 && month <= 12;
    const isValidYear = year >= 1000; // Assuming the year should be at least 1000

    // Check if the date itself is valid (e.g., no 31st February)
    const daysInMonth = new Date(year, month, 0).getDate();
    const isValidDate = isValidDay && isValidMonth && day <= daysInMonth;

    return isValidDate && isValidYear;
}

export const ensureSourceParameter = (url) => {
    // Create a URL object
    let urlObj = new URL(url);

    // Set the 'source' parameter to 'partner'
    urlObj.searchParams.set('source', 'partner');

    // Return the modified URL
    return urlObj.toString();
}

export const updateDurationLabelText = (duration) => {
    if (duration.value === -1) {
        return duration; // Keep "Until close" as is
    }

    const hours = Math.floor(duration.value / 60);
    const minutes = duration.value % 60;
    let newLabel = '';

    if (hours > 0) {
        newLabel += `${hours} hour${hours > 1 ? 's' : ''}`;

        if (minutes > 0) newLabel += ' and ';
    }

    if (minutes > 0) {
        newLabel += `${minutes} minute${minutes > 1 ? 's' : ''}`;
    }
    
    return { ...duration, label: newLabel };
}