import { apiPostRequest } from "../api";

export const getVenuesHtml = async (venues) => {
    const formData = new FormData();
    formData.append('venues', JSON.stringify(venues));
    formData.append('action', 'getVenuesHtml');

    const venuesHtml = await apiPostRequest(formData);

    return venuesHtml;
};