import { updateElemHTML } from ".";

export const toggleDepositSection = async (widget, depositInfo = false) => {
    if ( depositInfo ) {
        showDepositSection(widget, depositInfo);
    } else {
        hideDepositSection(widget);
    }
}

export const hideDepositSection = (widget) => {
    // Clear any current deposit info
    clearDepositInfo(widget);

    // Add the hidden class to the section
    //widget.querySelector('.ig-dmn__deposit').classList.add('hidden');
}

const clearDepositInfo = (widget) => {
    // Clear the HTML from the total cost elem
    updateElemHTML(widget, '.ig-dmn__deposit-total', '');
    
    // Clear the HTML from the description elem
    updateElemHTML(widget, '.ig-dmn__deposit-description', '');
}

const updateDepositInfo = (widget, amount, total, perType, type) => {
    const pounds = Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
    });

    // Clear the HTML from the total cost elem
    updateElemHTML(widget, '.ig-dmn__deposit-total', pounds.format(total));

    // Clear the HTML from the description elem
    updateElemHTML(widget, '.ig-dmn__deposit-description', `${pounds.format(amount)} per ${perType}`);
}

const showDepositSection = (widget, depositInfo) => {
    // Clear any current deposit info
    clearDepositInfo(widget);

    // Update the deposit info
    updateDepositInfo(
        widget,
        depositInfo.amount,
        depositInfo.total,
        depositInfo.amount_per,
        depositInfo.type,
    );

    // Remove the hidden class to the section
    //widget.querySelector('.ig-dmn__deposit').classList.remove('hidden');
}