
import { formatDateForDescription, isBookingFormValid, scrollToWidgetTop, updateElemHTML } from ".";
import { submitBooking } from "../api/booking";
import * as bookingData from "./bookingData";
import { getBookingTypeName } from "./bookingTypes";
import { showLoader, hideLoader, getWidgetEnv } from './widget';
import { showBookingCompleteModal } from "./modal";
import { getDetailsFormHTML } from "../ajax/bookingDetails";

export const getBookingDescriptionText = async (bookingDetails) => {
    const bookingTypeDetails = bookingData.getBookingTypeDetails();
    const bookingType = getBookingTypeName(bookingTypeDetails.name);
    const peopleText = bookingDetails.num_people === 1 ? 'person' : 'people';
    
    const description = `You are making a ${bookingType} booking for ${bookingDetails.num_people} ${peopleText} at ${bookingDetails.time} on ${formatDateForDescription(bookingDetails.date)}`;

    return description;
};

export const showCustomerDetails = async function (widget) {
    const bookingDetails = bookingData.getDmnData('bookingDetails');
    const formHTML = await getDetailsFormHTML(widget, bookingDetails);

    await updateElemHTML(widget, '.ig-dmn__inner', formHTML);

    const bookingDescription = await getBookingDescriptionText(bookingDetails);

    await updateElemHTML(widget, '.ig-dmn__details-booking-description-text', bookingDescription);
};

export const initDetailsForm = (widget) => {
    const form = widget.querySelector('.ig-dmn__details-form');
    const env = getWidgetEnv(widget);

    form.addEventListener('submit', (event) => {
        event.preventDefault();
        
        // Show the loader
        showLoader(widget);

        const errorElem = form.querySelector('.ig-dmn-form-error');

        // Clear any active error messages
        errorElem.classList.add('hidden');

        const isValid = isBookingFormValid(form);

        if ( isValid ) {
            // Check if DOB is set to be required and update the hidden field if so
            if ( form.querySelector('.ig-dmn__form-dates') ) {
                const day = form.querySelector('.ig-dmn__form-dob-day').value;
                const month = form.querySelector('.ig-dmn__form-dob-month').value;
                const year = form.querySelector('.ig-dmn__form-dob-year').value;
                const dob = `${year}-${month}-${day}`;
                
                form.querySelector('.ig-dmn__form-dob').value = dob;
            }

            // Run the submitBooking function
            submitBooking(env, form).then(booking => {
                // Scroll to the top of the widget
                scrollToWidgetTop(widget);
        
                // Show the bookingComplete modal
                showBookingCompleteModal(widget, booking).then(() => {                
                    setTimeout(() => {
                        hideLoader(widget);
                    }, 400);
                });
            });
        } else {
            // Show the error message
            errorElem.classList.remove('hidden');

            // Hide the loader
            hideLoader(widget);
        }        
    });
};
