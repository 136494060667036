import { apiPostRequest } from '.';
import { filterVenues } from '../helpers/venues';

export const getVenues = async (venuesAttr) => {
    const formData = new FormData();
    formData.append('action', 'getVenues');
    
    const allVenues = await apiPostRequest(formData);

    const venues = await filterVenues(allVenues, venuesAttr);
    
    return venues;
}