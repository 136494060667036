import * as bookingData from "../helpers/bookingData";
import { apiPostRequest } from "../api";
import { getMarketingPreferences, getVenuePackages } from "../api/venue";
import { getBookingRules } from "../api/bookingRules";
import { getWidgetEnv } from "../helpers/widget";
import { getTypeFromBookingTypeId } from "../helpers/bookingTypes";
import { ensureSourceParameter, getUrlParameterValue, hasUrlParameter } from "../helpers";

export const getDetailsFormHTML = async (widget, bookingDetails) => {
    const env = getWidgetEnv(widget);
    // If the widget is using the test environment for the formType to use the API
    const formType = env === 'test' ? 'api' : bookingDetails.formType;

    const formData = new FormData();
    formData.append('submit_type', formType);
    formData.append('action', 'getCustomerDetailsFormHtml');
    
    if ( formType === 'web' ) {
        // Need to proeed with the web route so return the iframe yrl for this.
        const stylesheet = `&stylesheet=${igniteDmnJsVars.stylesheet}`;
        const returnUrl = `&return_url=${igniteDmnJsVars.returnUrl}`;
        const iframeUrl = `${bookingData.getDmnData('bookingDetails').url}${stylesheet}${returnUrl}`;
        const validatedIframeUrl = ensureSourceParameter(iframeUrl);

        // Load the DMN Booking URL directly if the debug URL parameter equals true
        if ( hasUrlParameter('debug-dmn') && getUrlParameterValue('debug-dmn') === 'true' ) {
            window.location.href = validatedIframeUrl;    
        }

        formData.append('iframe_url', validatedIframeUrl);
    } else {
        // Grab the activeOffer from localStorage if set
        const offer = bookingData.getDmnData('activeOffer');

        // Get any packages avialable
        const packages = await getVenuePackages();
    
        // Get the marketing preferences
        const marketingPreferences = await getMarketingPreferences();
    
        // Get the booking policy from the booking rules
        const bookingRules = await getBookingRules(widget);
        const bookingPolicy = bookingRules.booking_notes;

        // Check if DOB is required for this booking type
        const bookingType = bookingData.getBookingTypeDetails();
        const typeOfBooking = getTypeFromBookingTypeId(bookingType.id) === 'Occasions' ? 'space_booking_' : 'table_booking_';
    
        // Append form data with values we've just got
        formData.append('offer', offer);
        formData.append('packages', JSON.stringify(packages));

        if ( bookingType.dob_required ) {
            formData.append('dob', true);
        }

        formData.append('marketing_preferences', JSON.stringify(marketingPreferences));
        formData.append('booking_policy', bookingPolicy);
        formData.append('type_of_booking', typeOfBooking);
    
        // Add the booking details to the form data
        Object.entries(bookingDetails).forEach(([key, value]) => {
            formData.append(key, value);
        });
    }

    const formHTML = await apiPostRequest(formData);

    return formHTML;
};
