import * as helpers from ".";
import * as bookingData from "./bookingData";
import { getOffersHTML } from "../ajax/offers";
import { toggleOffer } from './offers';
import { getBookingCompleteHTML } from "../ajax/bookingComplete";
import { initBookingTypesStage, initVenuesStage } from "./stages";
import { getVirtualTourHTML } from "../ajax/virtualTour";

export const initOffersModal = (widget) => {
    const offers = bookingData.getDmnData('offers');
    const trigger = widget.querySelector('.ig-dmn__offers-trigger');

    if (trigger && (offers && offers.length > 0)) {
        trigger.addEventListener('click', (event) => {
            event.preventDefault();

            // Open the modal
            openModal(widget);

            // Load in the offers
            getOffersHTML(offers).then(modalHTML => {
                helpers.updateElemHTML(widget, '.ig-dmn__modal-inner', modalHTML);

                // init toggleOffer function
                toggleOffer(widget);

                // Show the modal content after .4 seconds
                setTimeout(showModalContent(widget), 400);
            });
        });
    }

    // Call the initCloseModalTrigger function
    initCloseModalTrigger(widget);
};

export const initVirtualTourModal = (widget, bookingType) => {
    // Open the modal
    openModal(widget);

    getVirtualTourHTML(bookingType).then(modalHTML => {
        helpers.updateElemHTML(widget, '.ig-dmn__modal-inner', modalHTML);

        // Show the modal content after .4 seconds
        setTimeout(showModalContent(widget), 400);
    });

    // Call the initCloseModalTrigger function
    initCloseModalTrigger(widget);
};

export const showBookingCompleteModal = async (widget, booking) => {
    // Open the modal
    openModal(widget);

    // Load in the modal content
    const bookingCompleteHTML = await getBookingCompleteHTML(booking);
    helpers.updateElemHTML(widget, '.ig-dmn__modal-inner', bookingCompleteHTML);

    // Show the modal content after .4 seconds
    setTimeout(showModalContent(widget), 400);

    // Call the initCloseModalTrigger function
    initCloseModalTrigger(widget, 'complete');
};

const openModal = (widget) => {
    const modal = widget.querySelector('.ig-dmn__modal');
    const modalLoader = modal.querySelector('.ig-dmn__loader');
    const modalInner = modal.querySelector('.ig-dmn__modal-inner');

    // Clear the current offers in the modal
    helpers.updateElemHTML(widget, '.ig-dmn__modal-inner', '');

    // Show the loader
    modalLoader.classList.remove('hidden');

    // Hide the inner elem
    modalInner.classList.add('hidden');

    // Show the modal
    document.body.style.overflow = 'hidden';
    modal.classList.remove('hidden');
    modal.classList.add('flex');    
}

const showModalContent = (widget) => {
    const modal = widget.querySelector('.ig-dmn__modal');
    const modalLoader = modal.querySelector('.ig-dmn__loader');
    const modalInner = modal.querySelector('.ig-dmn__modal-inner');

    // Hide the loader
    modalLoader.classList.add('hidden');
        
    // Show the inner elem
    modalInner.classList.remove('hidden');    
}

const initCloseModalTrigger = (widget, stage = 'booking') => {
    const closeTrigger = widget.querySelector('.ig-dmn__modal-close');

    if (closeTrigger) {
        closeTrigger.addEventListener('click', (event) => {
            event.preventDefault();

            closeModal(widget, stage);
        });
    }    
}

export const closeModal = (widget, stage = 'booking') => {
    const modal = widget.querySelector('.ig-dmn__modal');

    if ( stage === 'complete' ) {
        // Booking is complete so refresh widget

        // Check if we should load the venues stage or the booking type stage
        const initStage = widget.getAttribute('data-venues') && widget.getAttribute('data-venues') != '' ? 'venues' : 'bookingTypes';
    
        if ( initStage === 'venues' ) {
            initVenuesStage(widget);
        } else {
            initBookingTypesStage(widget);
        }
    }

    // Hide the modal
    document.body.style.overflow = 'auto';
    modal.classList.remove('flex');
    modal.classList.add('hidden');
};

