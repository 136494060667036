import { scrollToWidgetTop } from ".";
import { showLoader, hideLoader } from './widget';
import { isBookingFormComplete } from "./bookingForm";
import { initStage } from './stages';
import { getDmnData } from "./bookingData";

export const initNavButtons = (widget) => {
    const buttons = widget.querySelectorAll('.ig-dmn__stage-trigger');

    if (buttons) {
        buttons.forEach(btn => {
            btn.addEventListener('click', (event) => {
                event.preventDefault();

                const stage = btn.getAttribute('data-stage');

                showLoader(widget);
                
                // Run the init stage function to call the next stage
                initStage(widget, stage).then(() => {
                    // Scroll to the top of the widget
                    scrollToWidgetTop(widget);

                    setTimeout(() => {
                        hideLoader(widget);
                    }, 400);
                });
            });
        });
    }
};

export const updateNavButtons = async (widget, valid, action, validation) => {
    const isFormComplete = isBookingFormComplete(widget);
    const enquireBtn = widget.querySelector('.ig-dmn__stage-trigger[data-stage="enquire"]');
    const proceedBtn = widget.querySelector('.ig-dmn__stage-trigger[data-stage="details"]');

    if ( isFormComplete ) {
        if ( action === 'accept' ) {
            // Booking is valid so hide the enquiry buttona nd show the proceed button
            enquireBtn.classList.add('!hidden', 'ig-dmn-btn--disabled');
            proceedBtn.classList.remove('!hidden', 'ig-dmn-btn--disabled');
        }

        if ( action === 'enquire' ) {
            // Can submit an enquiry but not proceed so enable the enquire button and disable the proceed button
            enquireBtn.classList.remove('!hidden', 'ig-dmn-btn--disabled');
            proceedBtn.classList.add('!hidden', 'ig-dmn-btn--disabled');
        }
        
        if ( action === 'may_enquire' ) {
            // Bookings are full but you can still submit an enquiry. You can't proceed though so enable the enquire button and disable the proceed button
            enquireBtn.classList.remove('!hidden', 'ig-dmn-btn--disabled');
            proceedBtn.classList.add('!hidden', 'ig-dmn-btn--disabled');

            /* 
            * TODO: Show an error message here saying this is unavailable but you can still enquire
            */
        }
    
        if ( action === 'reject' ) {
            // Can't proceed or submit an enquiry so disable both buttons
            enquireBtn.classList.add('!hidden', 'ig-dmn-btn--disabled');
            proceedBtn.classList.add('!hidden', 'ig-dmn-btn--disabled');

            /* 
            * TODO: Show an error message here saying this is unavailable
            */
        }        
    } else {
        resetNavButtons(widget);
    }
}

export const resetNavButtons = (widget) => {
    const enquireBtn = widget.querySelector('.ig-dmn__stage-trigger[data-stage="enquire"]');
    const proceedBtn = widget.querySelector('.ig-dmn__stage-trigger[data-stage="details"]');

    // Show the enquire button
    enquireBtn.classList.remove('!hidden');

    // Disable the enquire button if no bookingType is set in localStorage
    if ( !getDmnData('bookingType') ) {
        enquireBtn.classList.add('ig-dmn-btn--disabled');
    }

    // Show and disable the proceed button
    proceedBtn.classList.remove('!hidden');
    proceedBtn.classList.add('ig-dmn-btn--disabled');
};
