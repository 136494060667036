import { apiPostRequest } from ".";
import { getBookingDuration } from "./duration";
import * as bookingData from "../helpers/bookingData";
import { updatebookingFormOptions } from "../helpers/bookingForm";
import { toggleDepositSection } from "../helpers/deposit";
import { updateNavButtons } from "../helpers/navigation";
import { logDebugger } from "../helpers";

export const validateBooking = async function (widget, triggerType) {
    const venueId = bookingData.getDmnData('venue')._id;

    // Check if the booking is valid
    const validation = await getBookingValidation(widget);
    const isValid = validation.valid;

    if (isValid && validation.action !== 'reject') {
        // Update the bookingDetails in localStorage
        bookingData.updateBookingDetails({
            ...validation.bookingDetails,
            formType: validation.next.web ? 'web' : 'api',
            url: validation.next.web
        });

        // Update the validation in localStorage
        bookingData.updateValidation(validation);
    } else {
        // If a dropddown field is empty use the sugested values to populate this based on the infor selected
        // Display error messgae if all fields are added
        await updatebookingFormOptions(widget, validation.validation, triggerType);
    }

    // Hide/Show the deposit section
    await toggleDepositSection(widget, validation.depositRequired);

    // Update the nav buttons
    await updateNavButtons(widget, isValid, validation.action, validation);

    return validation;
};

export const getBookingValidation = async function (widget) {
    const bookingForm = widget.querySelector('.ig-dmn__booking-form');

    if (bookingForm) {
        // Work out the duration here and pass it through to the validation
        const duration = await getBookingDuration(widget);
        
        const formData = new FormData(bookingForm);
        formData.append('duration', duration);
        formData.append('action', 'getBookingValidation');

        const venueId = bookingData.getDmnData('venue')._id;
        if(venueId){
            formData.append('venueID', venueId);
        }

        const debugData = [];

        for (const data of formData.entries()) {
            if ( data[0] !== 'action' ) {                
                debugData.push({
                    key: data[0],
                    value: data[1]
                });
            }
        }

        logDebugger('Checking if booking details are valid - https://api.designmynight.com/v4/venues/VENUE_ID/booking-availability?type=BOOKING_TYPE_ID&date=DATE&num_people=2NUM_PEOPLE&time=TIME&duration=DURATION');
        logDebugger('Booking Details:');
        logDebugger(debugData);

        const validation = await apiPostRequest(formData);

        logDebugger('Validation:');
        logDebugger(validation);

        return validation;
    }
};
