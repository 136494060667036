import * as helpers from ".";
import * as bookingData from "./bookingData";
import { closeModal, initOffersModal } from './modal';

export const isOfferValid = (activeOffer) => {
    const offers = bookingData.getDmnData('offers').map(offer => {
        return offer._id;
    });

    return offers.includes(activeOffer);
}

export const updateOffers = (widget, offers) => {
    // Update the offers in localStorage
    bookingData.updateOffers(offers);

    // Check if there is an active offer set in localStorage and check if this is still available. If not then remove the html and hide the active offer elem.
    const activeOffer = bookingData.getDmnData('activeOffer');
    const isActiveOfferValid = isOfferValid(activeOffer);
    
    if ( !isActiveOfferValid ) {
        removeActiveOffer(widget);
    }
    
    // Hide the offers section if there are no offers available for the data given
    if ( offers.length > 0 ) {
        widget.querySelector('.ig-dmn__offers').classList.remove('hidden');
    } else {
        widget.querySelector('.ig-dmn__offers').classList.add('hidden');
    }

    // Run the initOffersModal function
    initOffersModal(widget);

    // Run the initRemoveOfferTrigger function
    initRemoveOfferTrigger(widget);
};

const removeActiveOffer = (widget) => {
    // Update the activeOffer in localStorage
    bookingData.updateActiveOffer(null);

    // Hide the active offer elem from the widget
    widget.querySelector('.ig-dmn__offers-active').classList.add('hidden');

    // Reset the HTML of the active offer
    helpers.updateElemHTML(widget, '.ig-dmn__offers-active-item', '');
}

const initRemoveOfferTrigger = (widget) => {
    const trigger = widget.querySelector('.ig-dmn__offers-active-remove');

    if ( trigger ) {
        trigger.addEventListener('click', (event) => {
            event.preventDefault();

            removeActiveOffer(widget);
        });
    }
};

export const toggleOffer = (widget) => {
    const triggers = widget.querySelectorAll('.ig-dmn__modal-offer');

    if (triggers) {
        triggers.forEach(trigger => {
            trigger.addEventListener('click', (event) => {
                event.preventDefault();

                const offer = trigger.getAttribute('data-offer-id');
                const offerTitle = trigger.querySelector('.ig-dmn__modal-offer-title').innerHTML;

                // Update the active offer in localStorage
                bookingData.updateActiveOffer(offer);

                // Update the active offer text
                helpers.updateElemHTML(widget, '.ig-dmn__offers-active-item', offerTitle);

                // Show the active offer element
                widget.querySelector('.ig-dmn__offers-active').classList.remove('hidden');

                // Hide the modal
                closeModal(widget);
            });
        });
    }
};
