import { getDaysOfWeek, getDatesBetween, getDayFromDate } from ".";
import { getDmnData } from "./bookingData"

const daysOfWeek = getDaysOfWeek(true);

export const getVenueClosedDays = () => {
    const unavailableDays = [];
    const venueData = getDmnData('venue');

    // Check for closed days set in opening_times
    if ( venueData.opening_times ) {
        Object.entries(venueData.opening_times).forEach(([key, value]) => {
            if ( daysOfWeek.includes(key) && !value.status && !unavailableDays.includes(key) ) {
                unavailableDays.push(daysOfWeek.indexOf(key));
            }
        });
    }

    return unavailableDays;
}

export const getBookingTypeClosedDays = () => {
    const unavailableDays = [];
    const venueData = getDmnData('venue');
    const bookingType = getDmnData('bookingType');

    if ( bookingType && venueData.booking_types ) {
        Object.entries(venueData.booking_types).forEach(([key, value]) => {
            if ( value.id == bookingType ) {
                Object.entries(value.booking_rules).forEach(([day, rules]) => {
                    if ( daysOfWeek.includes(day) && !rules.booking_available && !unavailableDays.includes(day) ) {
                        unavailableDays.push(daysOfWeek.indexOf(day));
                    }
                });
            }
        });
    }

    return unavailableDays;
}

export const getClosedDates = () => {
    let unavailableDates = [];
    const venueData = getDmnData('venue');

    // Check for closed days set in opening_times
    if ( venueData.opening_times ) {
        Object.entries(venueData.opening_times).forEach(([key, value]) => {
            if ( !daysOfWeek.includes(key) && !value.status && !unavailableDates.includes(key) ) {
                unavailableDates.push(key);
            }
        });
    }

    // Check for closed dates in booking_unavailable for all booking types
    if ( venueData?.booking_unavailable?.all ) {
        venueData?.booking_unavailable?.all.forEach(item => {
            unavailableDates = getDates(unavailableDates, item);
        });
    }

    // Check for closed dates in booking_unavailable for the active booking type
    if ( venueData?.booking_unavailable && getDmnData('bookingType') ) {
        Object.entries(venueData.booking_unavailable).forEach(([key, items]) => {
            if ( key === getDmnData('bookingType') ) {
                items.forEach(item => {
                    unavailableDates = getDates(unavailableDates, item);
                });
            }
        });
    }

    // Check for closed days for the active booking type in booking_types.booking_type.booking_overrides
    const bookingType = getDmnData('bookingType');

    if ( bookingType && venueData.booking_types ) {
        Object.entries(venueData.booking_types).forEach(([key, value]) => {
            if ( value.id == bookingType && value.booking_overrides ) {
                Object.entries(value.booking_overrides).forEach(([key, value]) => {
                    if ( !value.booking_available ) {
                        unavailableDates = getDates(unavailableDates, {
                            unavailable_from: value.date_from,
                            unavailable_to: value.date_to
                        });
                    }
                });
            }
        });
    }

    return unavailableDates;
}

const getDates = (dates, item) => {
    const fromTime = item.unavailable_from_time ?? '';
    const toTime = item.unavailable_to_time ?? '';

    if (fromTime === '' && toTime === '') {
        const datesArr = getDatesBetween(item.unavailable_from, item.unavailable_to);

        if ( datesArr.length > 0 ) {
            datesArr.forEach(date => {
                if ( !dates.includes(date) ) {
                    dates.push(date);
                }
            });
        }
    }
    
    return dates;
}

export const getOpenDates = (closedDates, bookingTypeClosedDays) => {
    const openDates = [];
    const venueOpenDates = [];
    const venueData = getDmnData('venue');

    // Check for open days set in opening_times
    if ( venueData.opening_times ) {
        Object.entries(venueData.opening_times).forEach(([key, value]) => {
            if ( !daysOfWeek.includes(key) && value.status ) {
                openDates.push(key);
                venueOpenDates.push(key);
            }
        });
    }

    // Check if vanue open dates are set on days when the booking type is unavailable
    if ( venueOpenDates.length ) {
        venueOpenDates.forEach(date => {
            const dayOfWeek = getDayFromDate(date);
            
            // If dayOfWeek is in bookingTypeClosedDays, remove it from openDates
            if ( bookingTypeClosedDays.includes(dayOfWeek) ) {
                openDates.splice(openDates.indexOf(date), 1);
            }
        });
    }

    // Check for open days for the active booking type in booking_types.booking_type.booking_overrides
    const bookingType = getDmnData('bookingType');

    if ( bookingType && venueData.booking_types ) {
        Object.entries(venueData.booking_types).forEach(([key, value]) => {
            if ( value.id == bookingType && value.booking_overrides ) {
                Object.entries(value.booking_overrides).forEach(([key, value]) => {
                    if ( value.booking_available ) {
                        // Get all dates this override
                        const bookingTypeOpenDates = getDates([], {
                            unavailable_from: value.date_from,
                            unavailable_to: value.date_to
                        });

                        // Loop through all dates and check if they exist in the closedDates []
                        bookingTypeOpenDates.forEach(date => {
                            if ( !closedDates.includes(date) ) {
                                // Date doesn't exist in the closed dates array so add it to the openDates array
                                openDates.push(date);
                            }
                        });
                    }
                });
            }
        });
    }

    return openDates; 
}