import * as bookingData from './bookingData';
import { showLoader, hideLoader, getWidgetType, getWidgetEnv } from './widget';
import { initDetailsForm, showCustomerDetails } from './bookingDetails';
import { initNavButtons } from './navigation';
import { initOffersModal } from './modal';
import { resizeIframe, updateElemHTML } from '.';
import { getBookingTypes } from '../api/bookingTypes';
import { enableBookingTypeTriggers, getBookingTypesHTML, initBookingTypeFilters } from './bookingTypes';
import { showEnquiryForm } from '../ajax/enquiryForm';
import { initEnquiryForm } from './enquiry';
import { initCalendar } from '../external/calendar';
import { virtualTourTriggers } from './virtualTours';
import { getVenues } from '../api/venues';
import { getVenuesHtml } from '../ajax/venues';
import { enableVenueTriggers } from './venues';

export const initVenuesStage = async (widget) => {
    // Reset the venue-id attribute
    widget.setAttribute('data-venue-id', '');

    const venuesAttr = widget.getAttribute('data-venues');

    // Show the loader
    showLoader(widget);

    // Reset bookingData
    bookingData.resetBookingData(widget, true);

    // Update the booking stage indicator
    updateBookingStageIndicator(widget, 'venue');

    // Init the stages triggers
    initStageTriggers(widget); 

    // Get all the venue groups venues
    const venues = await getVenues(venuesAttr);

    const venuesHtml = await getVenuesHtml(venues);

    // display the HTML
    updateElemHTML(widget, '.ig-dmn__inner', venuesHtml);

    setTimeout(() => {
        // Hide the loader elem
        hideLoader(widget);

        // Enable the venueTriggers
        enableVenueTriggers(widget);
    
        // Run the initNavButtons function
        initNavButtons(widget);
    }, 400);
};

export const initBookingTypesStage = async (widget) => {
    // Show the loader
    showLoader(widget);

    // Get the venue ID that the booking is being made for
    const venueId = widget.getAttribute('data-venue-id');
    const type = getWidgetType(widget);

    // Reset bookingData
    bookingData.resetBookingData(widget);

    // Update the booking stage indicator
    updateBookingStageIndicator(widget, 'type');

    // Init the stages triggers
    initStageTriggers(widget);

    // Get the booking types for this venue
    getBookingTypes(widget, type, true, venueId).then(bookingTypes => {
        getBookingTypesHTML(bookingTypes, type).then((bookingTypesHTML) => {
            // display the HTML
            updateElemHTML(widget, '.ig-dmn__inner', bookingTypesHTML);

            setTimeout(() => {
                // Hide the loader elem
                hideLoader(widget);

                // Initialize the bookingTypeFilters
                initBookingTypeFilters(widget);

                // Enable the bookingTypeTriggers
                enableBookingTypeTriggers(widget, venueId);

                // Enable the virtual tour triggers
                virtualTourTriggers(widget);
                
                // Run the initOffersModal function
                initOffersModal(widget);

                // Run the initNavButtons function
                initNavButtons(widget);
            }, 400);
        });
    });

};

export const initDetailsStage = async (widget) => {
    const env = getWidgetEnv(widget);
    const bookingDetails = bookingData.getDmnData('bookingDetails');
    // If the widget is using the test environment for the formType to use the API
    const formType = env === 'test' ? 'api' : bookingDetails.formType;

    // Get the HTML for the booking details page
    await showCustomerDetails(widget).then(() => {
        if (formType === 'api') {
            // Call the details form submit function
            initDetailsForm(widget);
        } else {
            const iframe = widget.querySelector('.ig-dmn__form-iframe');
            // iframe.onload = helpers.resizeIframe(iframe);
        }

        // Update the booking stage indicator
        updateBookingStageIndicator(widget, 'details');

        // Call the initStageTriggers function
        initStageTriggers(widget);
    });
};

export const initEnquiryStage = async (widget) => {
    const bookingForm = widget.querySelector('.ig-dmn__booking-form');

    // Load in the HTML for the enquiry form
    await showEnquiryForm(widget, bookingForm).then(() => {
        // Re-initialize the calendar
        // initCalendar(widget, 'details');

        // Call the details form submit function
        initEnquiryForm(widget);

        // Update the booking stage indicator
        updateBookingStageIndicator(widget, 'details');

        // Call the initStageTriggers function
        initStageTriggers(widget);
    });
}

export const initStage = async (widget, stage) => {
    if (stage === 'details') {
        // initialize the details stage
        await initDetailsStage(widget);
    }

    if (stage === 'enquire') {
        // Initialize the enquiry stage
        await initEnquiryStage(widget);
    }
}

export const initStageTriggers = (widget) => {
    const triggers = document.querySelectorAll('.ig-dmn__stage-section-trigger.past');

    if (triggers) {
        triggers.forEach(trigger => {
            trigger.addEventListener('click', (event) => {
                event.preventDefault();

                const stage = trigger.getAttribute('data-stage');

                if ( stage === 'reservation' ) {
                    
                }

                if ( stage === 'venue' ) {
                    initVenuesStage(widget);
                }

                if ( stage === 'type' ) {
                    initBookingTypesStage(widget);
                }
            });
        });
    }
};

export const updateBookingStageIndicator = (widget, stage) => {
    const stages = widget.querySelectorAll('.ig-dmn__stages-stage');

    // Loop through and remove the past and active classes from each stage element
    if ( stages ) {
        stages.forEach(stage => {
            stage.classList.remove('active');
            stage.classList.remove('past');
        });
    }

    if ( stage === 'reservation' ) {
        // 
    }

    if ( stage === 'venue' ) {
        // Add the past class to the reservations stage
        widget.querySelector('.ig-dmn__stages-stage[data-stage="reservation"]').classList.add('past');

        // Add the active class to the venue stage
        widget.querySelector('.ig-dmn__stages-stage[data-stage="venue"]').classList.add('active');
    }

    if ( stage === 'type' ) {
        // Add the past class to the reservations and venue stage
        widget.querySelector('.ig-dmn__stages-stage[data-stage="reservation"]').classList.add('past');
        if ( widget.querySelector('.ig-dmn__stages-stage[data-stage="venue"]') ) {
            widget.querySelector('.ig-dmn__stages-stage[data-stage="venue"]').classList.add('past');
        }

        // Add the active class to the type stage
        widget.querySelector('.ig-dmn__stages-stage[data-stage="type"]').classList.add('active');
    }

    if ( stage === 'details' || stage === 'enquire' ) {
        // Add the past class to the reservations, venue and type stages
        widget.querySelector('.ig-dmn__stages-stage[data-stage="reservation"]').classList.add('past');
        if ( widget.querySelector('.ig-dmn__stages-stage[data-stage="venue"]') ) {
            widget.querySelector('.ig-dmn__stages-stage[data-stage="venue"]').classList.add('past');
        }
        widget.querySelector('.ig-dmn__stages-stage[data-stage="type"]').classList.add('past');

        // Add the active class to the details booking stage
        widget.querySelector('.ig-dmn__stages-stage[data-stage="details"]').classList.add('active');
    }
};
