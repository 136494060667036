import { initBookingTypesStage, initVenuesStage } from './helpers/stages';

const widgetElems = document.querySelectorAll('.ig-dmn');

if ( widgetElems ) {
    // Initialise the booking types stage for each widget
    widgetElems.forEach(widget => {
        // Check if we should load the venues stage or the booking type stage
        const initStage = widget.getAttribute('data-venues') && widget.getAttribute('data-venues') != '' ? 'venues' : 'bookingTypes';

        if ( initStage === 'venues' ) {
            initVenuesStage(widget);
        } else {
            initBookingTypesStage(widget);
        }
    });
}

// Import main scss file here. This will compile to a css file in the dist folder
import '../scss/style.scss';