import { formatDateForDescription, isBookingFormValid, scrollToWidgetTop, updateDurationLabelText } from ".";
import { submitBooking } from "../api/booking";
import { getBookingTypeDetails, getDmnData, getBookingDetails } from "./bookingData";
import { getBookingTypeName } from "./bookingTypes";
import { showBookingCompleteModal } from "./modal";
import { getWidgetEnv, hideLoader, showLoader } from "./widget";

export const getEnquiryDetails = () => {
    const allDurations = getDmnData('validation')?.validation?.duration?.suggestedValues;
    const durations = allDurations ? allDurations.filter(duration => duration.valid).map(duration => updateDurationLabelText(duration)) : [];

    return {
        type: getDmnData('bookingType'),
        venueId: getDmnData('venue')._id,
        durations: durations
    }
};

export const getEnquiryDetailsText = async () => {
    const bookingTypeDetails = getBookingTypeDetails();
    const bookingDetails = getBookingDetails();
    const bookingType = getBookingTypeName(bookingTypeDetails.name);
    const numPeople = bookingDetails.num_people;
    const peopleText = numPeople === 1 ? 'person' : 'people';
    const time = bookingDetails.time;
    const date = formatDateForDescription(bookingDetails.date);
    
    const description = `You are making a ${bookingType} enquiry for ${numPeople} ${peopleText} at ${time} on ${date}`;

    return description;
};

export const initEnquiryForm = (widget) => {
    const form = widget.querySelector('.ig-dmn__details-form');
    const env = getWidgetEnv(widget);
    
    form.addEventListener('submit', (event) => {
        event.preventDefault();
        
        // Show the loader
        showLoader(widget);

        const errorElem = form.querySelector('.ig-dmn-form-error');

        // Clear any active error messages
        errorElem.classList.add('hidden');

        const isValid = isBookingFormValid(form);

        if ( isValid ) {
            // Check if DOB is set to be required and update the hidden field if so
            if ( form.querySelector('.ig-dmn__form-dates') ) {
                const day = form.querySelector('.ig-dmn__form-dob-day').value;
                const month = form.querySelector('.ig-dmn__form-dob-month').value;
                const year = form.querySelector('.ig-dmn__form-dob-year').value;
                const dob = `${year}-${month}-${day}`;
                
                form.querySelector('.ig-dmn__form-dob').value = dob;
            }

            // Run the submitBooking function
            submitBooking(env, form).then(booking => {
                // Scroll to the top of the widget
                scrollToWidgetTop(widget);

                // Show the bookingComplete modal
                showBookingCompleteModal(widget, booking).then(() => {                
                    setTimeout(() => {
                        hideLoader(widget);
                    }, 400);
                });
            });
        } else {
            // Show the error message
            errorElem.classList.remove('hidden');

            // Hide the loader
            hideLoader(widget);
        }
    });
};