import Choices from 'choices.js';
import '../../../../node_modules/choices.js/public/assets/styles/choices.css';
import { filterBookingTypesByCat } from '../helpers/bookingTypes';

let bookingTypeDropdown;

const defaultOptions = {
    allowHTML: false,
    itemSelectText: '',
    searchEnabled: false,
    shouldSort: false,
}

export const initBookingTypeFilterDropdown = (widget) => {
    const element = widget.querySelector('.ig-dmn__booking-type-filter-dropdown');

    if ( element ) {
        const options = {
            ...defaultOptions,
            labelId: 'Booking Type Category Filter Dropdown',
            placeholder: false,
        }
    
        const choices = new Choices(element, options);
    
        // Add a change event listener to filter the booking types on change
        element.addEventListener('change', (event) => {
            const category = event.detail.value;
    
            filterBookingTypesByCat(category);
        }, false);   
    
        return choices;
    }
}