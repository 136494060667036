import { getOffers } from "../api/offers";
import * as bookingData from "./bookingData";
import { showLoader, hideLoader } from './widget';
import { updateOffers } from './offers';
import { validateBooking } from "../api/bookingValidation";
import { updateTimeDropdown, updateGuestsDropdown } from ".";
import { initCalendar } from "../external/calendar";

export const updatebookingFormOptions = async function (widget, validation, triggerType) {
    let minGuests;
    let maxGuests;
    let times = [];
    
    // Loop through the validation object and get the values needed to update the fields
    Object.entries(validation).forEach(([key, item]) => {
        if ( key === 'num_people' ) {
            const rules = item.rules;

            // Add min and max people rules if they aren't set
            minGuests = rules?.min ?? 1;
            maxGuests = rules?.max ?? 8;
        }

        if ( key === 'time' ) {
            item.suggestedValues.forEach(valueObj => {
                if ( valueObj.action !== 'reject' ) {
                    times.push(valueObj.time);
                }
            });
        }
    });
    
    if ( triggerType === 'calendar' || triggerType === 'time' ) {
        await updateGuestsDropdown(widget, minGuests, maxGuests);
    }
    if ( triggerType === 'calendar' || triggerType === 'num_people' ) {
        await updateTimeDropdown(widget, times);
    }
};

export const initBookingValidationTriggers = (widget) => {
    const triggers = widget.querySelectorAll('.ig-dmn__booking-form-trigger');

    if (triggers) {
        triggers.forEach(trigger => {
            trigger.addEventListener('change', (event) => {
                showLoader(widget);

                const venueId = bookingData.getDmnData('venue')._id;

                // Update the offers available for the options selected
                getOffers(widget, venueId).then(offers => {
                    updateOffers(widget, offers);
                });

                // Validate the booking
                validateBooking(widget, trigger.getAttribute('name')).then(response => {
                    // Hide the loader
                    setTimeout(() => {
                        hideLoader(widget);
                    }, 400);
                });
            });
        });
    }
};

export const toggleFormDiscalimer = (widget, bookingTypeId) => {
    const bookingFormDisclaimer = widget.querySelector('.ig-dmn__form-disclaimer');

    // Hide the disclaimer
    if(bookingFormDisclaimer){
        bookingFormDisclaimer.classList.add('hidden');
    }

    // Check if the booking type has restricted dates and show the discliamer if so
    const bookingTypeDetails = bookingData.getBookingTypeDetails(bookingTypeId);

    if ( bookingTypeDetails.advance_bookings_max_days || bookingTypeDetails.advance_bookings_min_days ) {
        let disclaimerText = '';

        if (bookingTypeDetails.advance_bookings_max_days) {
            disclaimerText = `Please note: You can only book up to ${bookingTypeDetails.advance_bookings_max_days} days in advance.`;
        }

        if (bookingTypeDetails.advance_bookings_min_days) {
            disclaimerText = `Please note: You can only book up to ${bookingTypeDetails.advance_bookings_min_days} days before the booking.`;
        }

        if (bookingTypeDetails.advance_bookings_max_days && bookingTypeDetails.advance_bookings_min_days) {
            disclaimerText = `Please note: You can only book up to ${bookingTypeDetails.advance_bookings_max_days} days in advance and up to ${bookingTypeDetails.advance_bookings_min_days} days before the booking.`;
        }

       
        if(bookingFormDisclaimer){
            // Set the disclaimer text
            bookingFormDisclaimer.innerHTML = disclaimerText;

             // Show the disclaimer
            bookingFormDisclaimer.classList.remove('hidden');
        }
        
    }
}

export const showBookingForm = (widget) => {
    const bookingForm = widget.querySelector('.ig-dmn__booking-form');

    if (bookingForm) {
        // Reset any existing values in the form
        clearBookingForm(widget);

        // Show the form
        bookingForm.classList.remove('hidden');

        // Initialize the calendar
        initCalendar(widget);

        // Enable the enquiry button
        const enquireBtn = widget.querySelector('.ig-dmn__stage-trigger[data-stage="enquire"]');
        enquireBtn.classList.remove('!hidden', 'ig-dmn-btn--disabled');
    }
};

export const clearBookingForm = (widget) => {
    // Reset any existing values in the form
    widget.querySelector('.ig-dmn__form-datepicker').value = '';
    widget.querySelector('.ig-dmn__form-date').value = '';
    widget.querySelector('.ig-dmn__form-guests').value = '';
    widget.querySelector('.ig-dmn__form-time').value = '';

    const numPeopleDropdown = widget.querySelector('.ig-dmn__form-guests');
    const timesDropdown = widget.querySelector('.ig-dmn__form-time');

    // Remove the options from the dropdowns
    numPeopleDropdown.innerHTML = '<option value="">Select number of people</option>';
    timesDropdown.innerHTML = '<option value="">Select a time</option>';

    // Add the disabled attribute to the dropdowns
    numPeopleDropdown.setAttribute('disabled', '');
    timesDropdown.setAttribute('disabled', '');
};

export const isBookingFormComplete = (widget) => {
    const bookingForm = widget.querySelector('.ig-dmn__booking-form');

    if (bookingForm) {
        const date = widget.querySelector('.ig-dmn__form-date').value;
        const guests = widget.querySelector('.ig-dmn__form-guests').value;
        const time = widget.querySelector('.ig-dmn__form-time').value;

        return date !== '' && guests !== '' && time !== '';
    }

    // Can't find the booking form so return false
    return false;
};

