import { apiPostRequest } from ".";
import { logDebugger } from "../helpers";
import { filterBookingTypes } from "../helpers/bookingTypes";

export const getBookingTypes = async (widget, type, filter = true, venueId = null) => {
    let formData = new FormData();
    formData.append('data_type', 'all');
    formData.append('action', 'getBookingTypes');

    if(venueId != null){
        formData.append('venueID', venueId);
    }
    
    logDebugger('Getting booking types from DMN - https://api.designmynight.com/v4/venues/VENUE_ID?fields=booking_types');

    const allBookingTypes = await apiPostRequest(formData);

    logDebugger('Booking Types:');
    logDebugger(allBookingTypes);

    if ( filter ) {
        return await filterBookingTypes(widget, allBookingTypes, type);
    } else {
        return allBookingTypes;
    }
}