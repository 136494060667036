import { apiPostRequest } from "../api";
import { formatDateForDescription } from "../helpers";
import { getBookingTypeName } from "../helpers/bookingTypes";

export const getBookingCompleteHTML = async (booking) => {
    const status = getBookingStatus(booking.status);
    const enquiryType = isEnquiry(booking) ? 'enquiry' : 'booking';
    const completeMessage = await getBookingCompleteMessage(status, booking);
    const formData = new FormData();

    if ( status === 'success' ) {
        formData.append('show_upsell_booking_type', booking.type.id);
    }

    formData.append('status', status);
    formData.append('heading', status === 'success' ? `Thank you for your ${enquiryType}` : `Your ${enquiryType} was unsuccessful`);
    formData.append('message', completeMessage);
    formData.append('action', 'getBookingCompleteHtml');

    const responseJson = await apiPostRequest(formData);

    return responseJson;
};

const getBookingStatus = (bookingStatus) => {
    const successStatusArr = ['new', 'in_progress', 'complete', 'confirmed'];

    return successStatusArr.includes(bookingStatus) ? 'success' : 'error';
};

const isEnquiry = (booking) => {
    return booking.status === 'new' || booking.status === 'in_progress';
};

const getBookingCompleteMessage = async (status, booking) => {
    if ( status === 'success' ) {
        const enquiryType = isEnquiry(booking) ? 'enquiry' : 'booking';
        const intro = isEnquiry(booking) ? 'We have recieved' : 'We are happy to confirm';
        const bookingType = getBookingTypeName(booking.type.name);
        const peopleText = booking.num_people === 1 ? 'person' : 'people';

        return `${intro} your ${bookingType} ${enquiryType} for ${booking.num_people} ${peopleText} on ${formatDateForDescription(booking.date)}`;
    } else {
        const errorMessage = await getBookingErrorMessage();

        return errorMessage;
    }
};

const getBookingErrorMessage = async () => {
    const formData = new FormData();
    formData.append('action', 'getErrorMessage');

    const responseJson = await apiPostRequest(formData);

    return responseJson;
}