import { apiPostRequest } from "../api";

export const getVirtualTourIframe = async (bookingType) => {
    const formData = new FormData();
    formData.append('booking_type', bookingType);
    formData.append('action', 'getVirtualTourIframe');

    const iframe = await apiPostRequest(formData);

    return iframe;
}

export const getVirtualTourHTML = async (bookingType) => {
    const iframe = await getVirtualTourIframe(bookingType);
    const formData = new FormData();
    formData.append('iframe', iframe);
    formData.append('action', 'getVirtualTourHtml');

    const virtualTourHTML = await apiPostRequest(formData);

    return virtualTourHTML;
}